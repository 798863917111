$cellWidth: 4em;
$cellHeight: 4em;
$rowWidth: 3em;
$columnHeight: 3em;
$emptyColor: #E7E7E7;
$enabledColor: #F7F7F7;
$disabledColor: #838383;

.chessboard {
  width: 100%;
  overflow-x: auto;
  //height: 10em;
  //border: 1px solid red;
  margin-top: 3em;

  & .row {
    display: grid;
    column-gap: 1em;
    margin-bottom: 2em;
    grid-template-columns: repeat(18, 1fr);
  }

  & .cell {
    width: $cellWidth;
    height: $cellHeight;
    background-color: $enabledColor;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #323643;
    cursor: pointer;
    border-radius: 2px;
    position: relative;

    & .sales-line {
      width: 100%;
      height: 7px;
      background-color: #FAEADE;
      position: absolute;
      top: 0;
      border-radius: 2px 2px 0 0;
    }

    &_empty {
      background-color: $emptyColor;
    }

    &_disabled {
      background-color: $disabledColor;
    }

    &_selected {
      border: 2px solid #1F64E5;
    }

    &_row {
      width: $rowWidth;
    }
  }

  .header {
    .cell {
      height: $columnHeight;
      justify-content: flex-end;
      background-color: $emptyColor;

      &_first {
        width: $rowWidth;
      }
    }
  }
}
