.avatar {
  border-radius: 50em;
  width: 64px;
  height: 64px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #838383;
  margin-right: 2em;

  & img {
    width: 100%;
    height: 100%;
    border-radius: 50em;
    position: absolute;
  }

  .upload-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 0.2s ease-in-out all;
    backdrop-filter: brightness(0.7) blur(1px);
    border-radius: 50%;

    &:hover {
      opacity: 1;
    }
  }
}
