// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "~bootstrap/scss/functions";

// 2. Include any default variable overrides here

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "~bootstrap/scss/variables";
//@import "~bootstrap/scss/variables-dark";

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/helpers";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "~bootstrap/scss/utilities/api";

// 8. Add additional custom code here

.transform-none {
    transform: none;
    transform-origin: none;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
}

.custom-scroll::-webkit-scrollbar {
    width: 8px;
    /* ширина scrollbar */
    height: 8px;
}

.custom-scroll::-webkit-scrollbar-track {
    background: none;
    /* цвет дорожки */
}

.custom-scroll::-webkit-scrollbar-thumb {
    background-color: $body-bg;
    /* цвет плашки */
    border-radius: 20px;
    /* закругления плашки */
    border: 3px solid rgba(0, 0, 0, 0);
    /* padding вокруг плашки */
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
    background-color: #e0e0e0;
    transition: .2s;
}